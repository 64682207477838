import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { getBusiness, addBusiness, deleteCategoryforBusiness, updateCategoryforBusiness } from "../../../Utils/HelperFunctions";
import { toast } from "react-toastify"; // Assuming you are using react-toastify for notifications

const BusinessData = () => {
    const [businessData, setBusinessData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [newCategory, setNewCategory] = useState("");
    const [editMode, setEditMode] = useState(false); // To toggle between add and edit modes
    const [currentCategoryId, setCurrentCategoryId] = useState(null); // To store the ID of the category being edited
    const token = useSelector((state) => state?.Auth.user.data.token);

    const fetchData = async () => {
        try {
            setLoading(true); // Set loading to true while fetching data
            const data = await getBusiness(token);
            setBusinessData(data.result);
        } catch (error) {
            console.error("Failed to fetch business data:", error);
            toast.error("Failed to fetch business data");
        } finally {
            setLoading(false); // Set loading to false after fetching data
        }
    };

    useEffect(() => {
        fetchData();
    }, [token]);

    const editItem = (id, title) => {
        setCurrentCategoryId(id);
        setNewCategory(title);
        setEditMode(true); // Set edit mode to true
        setShowModal(true); // Open the modal
    };

    const deleteItem = async (id) => {
        try {
            await deleteCategoryforBusiness(token, id);
            await fetchData(); // Fetch updated data after deletion
        } catch (error) {
            console.error("Failed to delete category:", error);
            toast.error("Failed to delete category");
        }
    };

    const handleSaveChanges = async () => {
        try {
            const updatedCategoryData = { title: newCategory };
            await updateCategoryforBusiness(token, currentCategoryId, updatedCategoryData);
            await fetchData(); // Fetch updated data after update
            setNewCategory(""); // Clear the input field
            setShowModal(false); // Close the modal
            setEditMode(false); // Reset edit mode
        } catch (error) {
            console.error("Failed to update category:", error);
            toast.error("Failed to update category");
        }
    };

    const handleAddCategory = async () => {
        try {
            const newCategoryData = { title: newCategory };
            await addBusiness(token, newCategoryData);
            await fetchData(); // Fetch updated data after adding
            toast.success("Category added successfully!");
            setNewCategory(""); // Clear the input field
            setShowModal(false); // Close the modal
        } catch (error) {
            console.error("Failed to add category:", error);
            toast.error("Failed to add category");
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="w-full">
            <div className="flex justify-end p-4">
                <button
                    onClick={() => { setEditMode(false); setShowModal(true); }}
                    className="bg-primary text-white mr-12 px-4 py-2 rounded-md transition duration-300"
                >
                    Add Category
                </button>
            </div>

            {businessData.length > 0 ? (
                businessData.map((item) => (
                    <div
                        className="flex bg-white w-[95%] mx-auto flex-col px-4 mb-4 rounded-[10px] py-4 shadow-sm lg:w-[1045px] md:m-auto md:mb-4 lg:py-0 responsive-inner-container"
                        key={item._id}
                    >
                        <div className="flex justify-between items-center">
                            <h3 className="text-base md:text-[21px] text-[#000000] font-g-bold leading-[24.61px]">
                                {item.title}
                            </h3>
                            <div className="flex space-x-4">
                                <button
                                    className="block-btn m-2 px-4 py-2 bg-slate-300 text-white rounded-md hover:bg-blue-600 transition duration-300"
                                    onClick={() => editItem(item._id, item.title)}
                                >
                                    Edit
                                </button>
                                <button
                                    className="block-btn m-2 px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition duration-300"
                                    onClick={() => deleteItem(item._id)}
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                            {/* <p className="text-base md:text-[16px] leading-[20px] font-g-regular text-[#000000]"> */}
                                {/* Published: {item.isPublished ? "Yes" : "No"} */}
                            {/* </p> */}
                    </div>
                ))
            ) : (
                <div className="text-center text-gray-500">No categories available.</div>
            )}

            {/* Modal */}
            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="bg-white p-8 rounded-lg shadow-lg w-[400px]">
                        <h2 className="text-2xl mb-4">{editMode ? "Edit Category" : "Add New Category"}</h2>
                        <label className="block text-sm font-medium text-gray-700">Category Title</label>
                        <input
                            type="text"
                            value={newCategory}
                            onChange={(e) => setNewCategory(e.target.value)}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                        />
                        <div className="flex justify-end mt-6 space-x-4">
                            {editMode ? (
                                <button
                                    onClick={handleSaveChanges}
                                    className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300"
                                >
                                    Save
                                </button>
                            ) : (
                                <button
                                    onClick={handleAddCategory}
                                    className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300"
                                >
                                    Add
                                </button>
                            )}
                            <button
                                onClick={() => setShowModal(false)}
                                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600 transition duration-300"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BusinessData;
