import React, { useState } from "react";
import { useSelector } from "react-redux";
import TopNav from "../../../Components/TopNav/TopNav";
import { addCategory } from "../../../Utils/HelperFunctions";

const AddCategory = () => {
  const token = useSelector((state) => state?.Auth?.user?.data?.token);
  const [categoryName, setCategoryName] = useState("");
  const [categoryType, setCategoryType] = useState("");

  const handleCategoryNameChange = (e) => {
    setCategoryName(e.target.value);
  };

  const handleCategoryTypeChange = (e) => {
    setCategoryType(e.target.value);
  };

  const handleSave = async () => {
    if (categoryName && categoryType) {
      try {
        await addCategory(
          { name: categoryName, categoryType: categoryType },
          token
        );
        console.log({ name: categoryName, categoryType: categoryType }, token);
        setCategoryName("");
        setCategoryType("");
      } catch (error) {
        console.error("Error adding category:", error);
      }
    }
  };

  return (
    <div>
      <TopNav title="Slider Management" />
      <div className="w-[1160px] flex flex-row items-start justify-end py-0 px-6 box-border max-w-full">
        <div className="flex-1 shadow-[0px_4px_21px_12px_rgba(0,_0,_0,_0.05)] rounded-sm bg-white flex flex-col items-start justify-start pt-7 px-7 pb-[33px] box-border gap-[30px] max-w-full mq750:gap-[15px] mq750:pt-5 mq750:pb-[21px] mq750:box-border">
          <b className="w-52 h-[22px] relative leading-[26px] capitalize font-bold inline-block shrink-0 z-[1] mq450:text-2xl mq450:leading-[21px]">
            Add Category
          </b>
          <div className="w-[520px] flex flex-col items-start justify-start gap-[20px] max-w-full text-base text-darkgray-200">
            <div className="self-stretch rounded-lg box-border flex flex-row items-start justify-start py-5 px-[22px] max-w-full z-[1] border-[1px] border-solid border-silver">
              <input
                className="w-full [border:none] [outline:none] font-gilroy text-base bg-[transparent] relative leading-[16px] capitalize text-darkgray-200 text-left flex items-center p-0 z-[2]"
                placeholder="Category Name"
                type="text"
                value={categoryName}
                onChange={handleCategoryNameChange}
              />
            </div>
            <div className="self-stretch flex flex-col items-start justify-start max-w-full">
              <div className="self-stretch rounded-t-lg rounded-b-none flex flex-col items-start justify-start py-5 px-px gap-[21.5px] z-[2] mt-[-1px] text-darkslategray-200 border-[1px] border-solid border-silver">
                <div className="w-full flex flex-row items-start justify-start py-0 px-[21px] box-border">
                  <div className="flex-1 relative leading-[10px] capitalize z-[3]">
                    <select
                      className="w-full [border:none] [outline:none] font-gilroy text-base bg-[transparent] relative leading-[16px] capitalize text-darkgray-200 text-left flex items-center p-0 z-[2]"
                      value={categoryType}
                      onChange={handleCategoryTypeChange}
                    >
                      <option value="" disabled>
                        Select Category
                      </option>
                      <option value="1">Print</option>
                      <option value="2">Coupon Code</option>
                      <option value="3">Product</option>
                      <option value="4">Service</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="cursor-pointer py-4 px-[59px] bg-darkturquoise w-[162px] rounded-lg box-border flex flex-row items-start justify-start z-[1] border-[1px] border-solid border-darkturquoise hover:bg-cadetblue-100 hover:box-border hover:border-[1px] hover:border-solid hover:border-cadetblue-100"
              onClick={handleSave}
            >
              <div className="flex-1 relative text-lg leading-[18px] capitalize font-gilroy text-white text-center">
                Save
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCategory;
