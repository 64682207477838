import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import trash from "../../../Assets/img/trashBlack.svg";

import { getCategoryList } from "../../../Utils/HelperFunctions";
import { deleteCategory } from "../../../Utils/HelperFunctions";

const CateforyBottunSlider = ({ setSelectedCategoryId }) => {
  const token = useSelector((state) => state?.Auth.user.data.token);
  const [categoryData, setCategoryData] = useState([]);
  const [activeButton, setActiveButton] = useState("0");
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [hover, setHover] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [categoryIdToDelete, setCategoryIdToDelete] = useState(null);

  const toggleModal = (categoryId) => {
    setCategoryIdToDelete(categoryId);
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    setSelectedCategoryId(selectedCategory);
    console.log("toggleModal-------------------->", selectedCategory);
  }, [selectedCategory, setSelectedCategoryId]);

  const getCategory = async () => {
    let data = await getCategoryList(token);

    setCategoryData(data.data);
    setSelectedCategoryId(data.data[0]._id);
    setSelectedCategory(data.data[0]._id);
  };

  useEffect(() => {
    getCategory();
  }, [loading]);

  const handleDeleteCategory = async () => {
    setLoading(true);
    let response = await deleteCategory(token, categoryIdToDelete);
    setLoading(false);
    setIsModalOpen(false);
  };

  const handleButtonClick = (index, categoryId) => {
    setActiveButton(index);
    setSelectedCategoryId(categoryId);
  };

  return (
    <div className="shadow-[0px_4px_21px_12px_rgba(0,_0,_0,_0.05)] rounded-xl  bg-white self-stretch flex flex-row items-start justify-start max-w-full p-[13px] box-border gap-[19.8px] max-w-full text-lg text-darkslategray-200 overflow-x-auto">
      {categoryData.map((category, index) => (
        <button
          key={category._id}
          style={{
            backgroundColor:
              activeButton == index
                ? "rgb(18 196 224 / var(--tw-bg-opacity))"
                : hover
                ? "dimgray"
                : "transparent",
            cursor: "pointer",
            padding: "1rem",
            width: "auto",
            flex: "1",
            borderRadius: "0.5rem",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "row",
            alignItems: "start",
            minWidth: "82px",
            zIndex: 1,
            border:
              activeButton == index
                ? "1px solid darkslategray"
                : "1px solid dimgray",
            ":hover": {
              backgroundColor: "dimgray",
              boxSizing: "border-box",
              border: "1px solid dimgray",
            },
          }}
        >
          <div
            onClick={() => handleButtonClick(index, category._id)}
            className="w-full  relative text-lg leading-[18px] capitalize font-gilroy text-darkslategray-200 text-center justify-center inline-block min-w-[82px]"
          >
            <div className="flex justify-between items-center">
              {category.name}
            </div>
          </div>

          <img
            onClick={() => toggleModal(category._id)}
            className="flex justify-end"
            src={trash}
            alt="trash icon"
          />
        </button>
      ))}

      {isModalOpen && (
        <div
          id="deleteModal"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-gray-800 bg-opacity-50"
        >
          <div className="relative p-4 w-full max-w-md h-full md:h-auto">
            <div className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
              <button
                type="button"
                className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={toggleModal}
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <svg
                className="text-gray-400 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <p className="mb-4 text-gray-500 dark:text-gray-300">
                Are you sure you want to delete this item?
              </p>
              <div className="flex justify-center items-center space-x-4">
                <button
                  onClick={toggleModal}
                  className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-500 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                >
                  No, cancel
                </button>
                <button
                  type="submit"
                  onClick={() => handleDeleteCategory()}
                  className="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900"
                >
                  Yes, I'm sure
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CateforyBottunSlider;
