import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import tshir from "../../../Assets/img/tshrit.svg";
import { useSelector } from "react-redux";
import {
  getRewardByCategory,
  updateRewardDetails,
} from "../../../Utils/HelperFunctions";

const GetRewards = ({ selectedCategoryId }) => {
  const token = useSelector((state) => state?.Auth.user.data.token);
  const [activeStates, setActiveStates] = useState({});
  const [rewardsData, setRewardsData] = useState(null);
  const [loading, setLoading] = useState(false);

  const getCategory = async () => {
    if (!selectedCategoryId) return;
    setLoading(true);
    let data = await getRewardByCategory(token, selectedCategoryId);
    setRewardsData(data);
    setLoading(false);
    const initialActiveStates = {};
    data.forEach((reward) => {
      initialActiveStates[reward._id] = reward.status;
    });
    setActiveStates(initialActiveStates);
  };

  useEffect(() => {
    getCategory();
  }, [selectedCategoryId]);

  const toggleActive = async (rewardId, status) => {
    await updateRewardDetails(rewardId, { status: !status }, token);

    setActiveStates((prevStates) => ({
      ...prevStates,
      [rewardId]: !status,
    }));
  };

  return (
    <>
      {rewardsData === null ? (
        <p>Loading...</p>
      ) : rewardsData.length === 0 ? (
        <div className="flex justify-center items-center h-full w-full">
          <p>No rewards found for this category.</p>
        </div>
      ) : (
        rewardsData.map((reward) => (
          <div
            key={reward._id}
            className="w-full shadow-[0px_4px_21px_12px_rgba(0,_0,_0,_0.05)] rounded-sm bg-white flex flex-row items-end justify-between py-2.5  pl-2.5 box-border max-w-full gap-[20px] mq1050:flex-wrap mq1050:justify-center"
          >
            <div className="w-full flex flex-row items-start justify-center gap-[27px] max-w-full mq750:flex-wrap">
              <div className="shadow-[0px_0px_13px_rgba(0,_0,_0,_0.15)] rounded-3xs bg-lightcyan-100 flex flex-row items-start justify-start py-4 pr-[15px] pl-[17px] z-[1]">
                <img
                  className="h-[104px] w-[104px] relative object-contain z-[2]"
                  loading="lazy"
                  alt=""
                  src={reward.image_url || tshir}
                />
              </div>
              <div className="flex-1 flex flex-col items-start justify-start pt-6 px-0 pb-0 box-border min-w-[337px] max-w-full mq450:min-w-full">
                <div className="self-stretch flex flex-col items-start justify-start gap-[20px]">
                  <h3 className="m-0 h-6 relative text-inherit leading-[28px] capitalize font-bold font-inherit inline-block shrink-0 z-[1] mq450:text-3xl mq450:leading-[22px]">
                    {reward.name}{" "}
                  </h3>
                  <p className="m-0 self-stretch h-11 relative text-base leading-[26px] text-darkslategray-200 inline-block shrink-0 z-[1]">
                    {reward.description.length > 130
                      ? reward.description.substring(0, 130) + "..."
                      : reward.description}
                  </p>
                </div>
              </div>
              <div className="w-[199px] flex mx-6 flex-col items-center justify-center py-3.5 px-0 box-border text-center text-3xs text-darkgray-100">
                <div className="self-stretch flex flex-col items-start justify-start gap-[18px]">
                  <div className="flex flex-row items-start justify-start gap-3 self-stretch shadow-[0px_0px_2px_rgba(0,_0,_0,_0.21)_inset] rounded-xl bg-lightcyan-200 cursor-pointer">
                    <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only peer flex flex-row items-start justify-start gap-3 self-stretch shadow-[0px_0px_2px_rgba(0,_0,_0,_0.21)_inset] rounded-xl bg-lightcyan-200 cursor-pointer"
                        checked={activeStates[reward._id]}
                        onChange={() =>
                          toggleActive(reward._id, activeStates[reward._id])
                        }
                      />
                      <div
                        className={`w-[100px] flex items-start justify-center py-[9.5px] px-[27px] rounded-xl box-border whitespace-nowrap shadow-[0px_0px_7px_rgba(0,_0,_0,_0.15)] bg-white transition-all duration-300 ease-in-out ${(reward.status =
                          activeStates[reward._id]
                            ? "bg-transparent shadow-none"
                            : "bg-white")}`}
                      >
                        <div className="flex-1 relative leading-[10px] capitalize inline-block min-w-[40px] z-[2]">
                          in active
                        </div>
                      </div>
                      <div className="w-[29px] flex flex-col items-start justify-start  px-0 pb-0 box-border">
                        <div
                          className={`w-[100px] flex items-start justify-center py-[7px] px-[27px]  rounded-xl box-border whitespace-nowrap shadow-[0px_0px_7px_rgba(0,_0,_0,_0.15)] bg-white transition-all duration-300 ease-in-out ${(reward.status =
                            activeStates[reward._id]
                              ? "bg-white  "
                              : "bg-transparent shadow-none")}`}
                        >
                          active
                        </div>
                      </div>
                    </label>
                  </div>
                  <Link
                    to={`/RewardManagement/rewardDetails/${reward._id}`}
                    className="cursor-pointer pt-[17px] pb-[18px] pr-[47px] pl-12 bg-[transparent] self-stretch rounded-3xs flex flex-row items-start justify-start whitespace-nowrap z-[1] border-[1px] border-solid border-gray-200 hover:bg-darkslategray-300 hover:box-border hover:border-[1px] hover:border-solid hover:border-darkslategray-100"
                  >
                    <div className="h-[54px] w-[199px] relative rounded-3xs box-border hidden border-[1px] border-solid border-gray-200"></div>
                    <div className="flex-1 relative text-lg leading-[18px] capitalize font-gilroy text-gray-200 text-center inline-block min-w-[102px] z-[2]">
                      view details
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </>
  );
};

export default GetRewards;
