import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Spinner from "../../../Components/spinner/Spinner";
import { dummyUsersData } from "../../../Utils/DummyUsers";
import { getReportedBugsData } from "../../../Utils/HelperFunctions";
import SearchInput from "../../../Components/SearchInput/SearchInput";

const ReportedBugUsers = () => {
  const [usersData, setUsersData] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filterUsers, setFilterUsers] = useState(null);
  const token = useSelector((state) => state?.Auth.user.data.token);

  // Get  Users Data
  const getData = async () => {
    let data = await getReportedBugsData(token);
    setUsersData(data.result);
  };
  useEffect(() => {
    getData();
  }, []);
  // useEffect(() => {
  //   const filter = usersData?.filter((data) =>
  //     data.user.name.toLowerCase().includes(searchText)
  //   );
  //   setFilterUsers(filter);
  // }, [searchText]);

  if (!usersData) {
    return <Spinner />;
  }
  return (
    <div className="max-h-[85vh] overflow-hidden">
      {/* Optional Search Input */}
      {/* <SearchInput
      placeholder='Search users by name'
      setSearchText={setSearchText}
    /> */}

      <div className="mx-4 md:mx-8 h-[75vh] overflow-y-scroll custom-scroll-bar">
        {usersData.length > 0 ? (
          usersData.map((data, index) => (
            <div
            className="bg-white py-6 px-6 mb-4 rounded-lg shadow-sm flex items-center"
            key={index}
          >
            {/* Profile Section */}
            <div className="flex-shrink-0 flex flex-col items-center mr-4">
              <img
                src={data.user.profilePicture}
                alt={data.user.name}
                className="bg-gray-200 w-24 h-24 rounded-full mb-2"
              />
              <h3 className="text-lg font-bold text-gray-800 text-center">
                {data.user.name}
              </h3>
            </div>
            {/* Content Section */}
            <div className="flex-1">
              <div className="mb-2 flex items-center">
                <label className="text-gray-900 text-sm font-bold mr-2">Title:</label>
                <h4 className="text-lg text-gray-800">{data.title}</h4>
              </div>
              <div className="mb-2 flex items-center">
                <label className="text-gray-900 text-sm font-bold mr-2">Page:</label>
                <h5 className="text-lg text-gray-800">{data.page}</h5>
              </div>
              <div className="flex items-center">
                <label className="text-gray-900 text-sm font-bold mr-2">Message:</label>
                {data.message.split(" ").slice(0, 50).join(" ") + (data.message.split(" ").length > 50 ? "..." : "")}
                </div>
            </div>
          </div>
          
          ))
        ) : (
          <p className="text-center text-gray-500">No Data Found</p>
        )}
      </div>
    </div>
  );
};

export default ReportedBugUsers;
