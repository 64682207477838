import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cake from "../../../Assets/img/cake.png";
import Trash from "../../../Assets/img/trash.svg";
import {
  getBlogs,
  deleteArticle,
  updateArticle,
} from "../../../Utils/HelperFunctions";
import { useSelector } from "react-redux";
import ArticleDescription from "./ArticleDescription";

const ArticlesData = () => {
  const token = useSelector((state) => state?.Auth.user.data.token);
  const [blogsData, setBlogsData] = useState([]);
  const [filterArticles, setFilterArticles] = useState(null);
  const navigate = useNavigate();

  console.log("token", token);
  // Function to fetch blogs
  const getBlogsFunc = async () => {
    try {
      const data = await getBlogs(token); // Pass token to the function
      console.log(data);
      setBlogsData(data);
    } catch (error) {
      console.error("Failed to fetch blogs:", error);
    }
  };

  useEffect(() => {
    getBlogsFunc();
  }, []);

  // Function to handle toggle switch
  const handleToggle = async (articleId, currentStatus) => {
    const newStatus = !currentStatus; // Toggle the current status
    try {
      await updateArticle(token, articleId, { isPublished: newStatus });
      getBlogsFunc(); // Refresh the blog list
    } catch (error) {
      console.error("Failed to update article status:", error);
    }
  };

  // Function to handle article deletion
  const handleDeleteArticle = async (articleID) => {
    try {
      const response = await deleteArticle(token, articleID); // Pass token and articleID to the function
      console.log("Article deleted:", response);
      getBlogsFunc(); // Refresh the blog list
    } catch (error) {
      console.error("Failed to delete article:", error);
    }
  };

  return (
    <div className="max-h-[95vh]">
      <div>
        <div className="flex justify-end mx-16 mb-6">
          <Link
            to="/articles/addArticle"
            className="cursor-pointer w-40 border-none pt-[19px] px-5 pb-5 bg-darkturquoise shadow-[0px_4px_21px_12px_rgba(0,_0,_0,_0.05)] rounded-3xs flex items-start justify-center min-w-[136px] max-w-full hover:bg-cadetblue-100"
          >
            <div className="w-[136px] text-lg leading-[18px] capitalize font-gilroy text-white text-center">
              Add Article
            </div>
          </Link>
        </div>
      </div>
      <div className="  w-full max-h-[95vh] overflow-y-auto custom-scroll-bar responsive-container">
        {blogsData.length > 0 ? (
          blogsData.map((data, index) => (
            <div
              className="bg-white py-2 cursor-pointer w-[95%] mx-auto min-h-[150px] flex flex-col justify-center px-4 mb-4 rounded-[10px] shadow-sm  h-auto md:m-auto md:mb-4 responsive-inner-container"
              key={index}
              // onClick={() => navigate(`/articles/${data._id}`)}
            >
              <div className="flex items-center justify-between">
                <div className="flex flex-col lg:flex-row lg:items-center">
                  {data.images.length > 0 ? (
                    <img
                      src={data.images[0].file}
                      alt="article-img"
                      className="w-[170px] h-[150px] md:w-[200px] lg:w-[223px] md:h-[176px] rounded-[10px]"
                    />
                  ) : (
                    <img
                      src={Cake}
                      alt="article-img"
                      className="w-[170px] h-[150px] md:w-[200px] lg:w-[223px] md:h-[176px] rounded-[10px]"
                    />
                  )}
                  <div className="flex flex-col my-5">
                    <div className="flex flex-col lg:flex-row md:justify-between mb-2">
                      <h3 className="text-base lg:ml-4 md:pt-2 lg:pt-0 md:text-[22px] text-[#000000] font-g-bold line-height-[25.78px]">
                        {data.title}
                      </h3>
                    </div>
                    <ArticleDescription data={data} />
                  </div>

                  <div className=" items-center">
                    <label
                      htmlFor={`toggle-${data._id}`}
                      className="flex items-center justify-between  cursor-pointer"
                    >
                      <div className="relative">
                        <input
                          id={`toggle-${data._id}`}
                          type="checkbox"
                          className="sr-only"
                          checked={data.isPublished}
                          onChange={() =>
                            handleToggle(data._id, data.isPublished)
                          }
                        />
                        <div className="block bg-lightcyan-100 w-14 h-8 rounded-full"></div>
                        {/* <div className="ml-3 text-gray-700 font-medium">
                          {data.isPublished ? "Published" : "Not Published"}
                        </div> */}
                        <div
                          className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition ${
                            data.isPublished
                              ? "transform translate-x-full bg-lightBlue"
                              : ""
                          }`}
                        ></div>
                      </div>
                    </label>

                    <button
                      onClick={() => handleDeleteArticle(data._id)}
                      className="w-[23px] h-[23px] mt-7 rounded-full bg-[#F80D0D] flex items-center justify-center ml-2 md:ml-3 lg:ml-4"
                    >
                      <img src={Trash} alt="trash-icon" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center">No Data Found</p>
        )}
      </div>
    </div>
  );
};

export default ArticlesData;
