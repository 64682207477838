import React from "react";

const ArticleDescription = ({ data }) => {
  const maxLength = 200; // Set the desired maximum length for the description
  const htmlContent = data.description;

  const truncateHtml = (html, maxLength) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    let truncated = "";
    let length = 0;

    const walk = (node) => {
      if (length >= maxLength) return;

      if (node.nodeType === Node.TEXT_NODE) {
        const text = node.nodeValue;
        if (length + text.length > maxLength) {
          truncated += text.substring(0, maxLength - length);
          length = maxLength;
        } else {
          truncated += text;
          length += text.length;
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        truncated += `<${node.nodeName.toLowerCase()}`;
        for (let i = 0; i < node.attributes.length; i++) {
          const attr = node.attributes[i];
          truncated += ` ${attr.name}="${attr.value}"`;
        }
        truncated += ">";

        for (let i = 0; i < node.childNodes.length; i++) {
          walk(node.childNodes[i]);
          if (length >= maxLength) break;
        }

        truncated += `</${node.nodeName.toLowerCase()}>`;
      }
    };

    for (let i = 0; i < tempDiv.childNodes.length; i++) {
      walk(tempDiv.childNodes[i]);
      if (length >= maxLength) break;
    }

    return truncated;
  };

  const truncatedHtmlContent = truncateHtml(htmlContent, maxLength);

  return (
    <p
      className="lg:px-4 text-base line-height-[23px] font-g-regular text-customGray articles-text"
      dangerouslySetInnerHTML={{ __html: truncatedHtmlContent }}
    />
  );
};

export default ArticleDescription;
