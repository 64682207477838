import React, { useState } from 'react';

const SwitchToggleButton = ({ onToggle, width = '20rem' }) => {
  const [activeButton, setActiveButton] = useState('first');

  const toggleButton = () => {
    const newActiveButton = activeButton === 'first' ? 'second' : 'first';
    setActiveButton(newActiveButton);
    onToggle(newActiveButton === 'first'); // Calls onToggle with true for "first" and false for "second"
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
      <div
        style={{
          position: 'relative',
          width: width,
          height: '2.5rem',
          backgroundColor: '#D1D5DB', // bg-gray-300
          borderRadius: '9999px', // rounded-full
          cursor: 'pointer',
          overflow: 'hidden', // Ensures the toggle knob stays within the container
        }}
        onClick={toggleButton}
      >
        <div
          style={{
            position: 'absolute',
            top: '0',
            height: '2.5rem',
            width: '51%', // Half of the container width for the toggle knob
            borderRadius: '9999px',
            transition: 'transform 0.3s ease-in-out',
            transform: activeButton === 'first' ? 'translateX(0)' : `translateX(${width.split('rem')[0] / 2}rem)`, // Adjusted for dynamic width
            backgroundColor: activeButton === 'first' ? 'rgb(18, 196, 224)' : 'rgb(18, 196, 224)', // Active color
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: '0',
            left: '0.5rem',
            right: '0.5rem',

            height: '2.5rem',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 0.75rem',
            color: '#FFFFFF', // text-white
            fontSize: '0.875rem', // text-sm
            fontWeight: 'bold', // font-g-bold
          }}
        >
          <span>List of Orders</span>
          <span>List of Requests</span>
        </div>
      </div>
    </div>
  );
};

export default SwitchToggleButton;
