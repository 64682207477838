import React, { useEffect, useState } from "react";
import TopNav from "../../../Components/TopNav/TopNav";
import { useSelector } from "react-redux";
import {
  getRewardPoint,
  updateRewardPoint,
} from "../../../Utils/HelperFunctions";

const UpdateRewardPoints = () => {
  const token = useSelector((state) => state?.Auth.user.data.token);
  const [rewardPoints, setRewardPoints] = useState({});
  const [vendorPoints, setVendorPoints] = useState("");
  const [shoppersPoint, setShoppersPoint] = useState("");
  const [priceValue, setPriceValue] = useState("");
  const [pointValue, setPointValue] = useState("");
  const [vendorPointPer, setVendorPointPer] = useState("");
  const [shoppersPointPer, setShoppersPointPer] = useState("");

  console.log("vendorPoints", priceValue);
  console.log("shoppersPoint", pointValue);
  console.log("shoppersPoint", vendorPointPer);
  console.log("shoppersPoint", shoppersPointPer);

  const fetchRewardData = async () => {
    let data = await getRewardPoint(token);
    setPriceValue(data.data.priceValue);
    setPointValue(data.data.pointValue);
    setVendorPointPer(data.data.vendorPointPer);
    setShoppersPointPer(data.data.shoppersPointPer);
    setRewardPoints(data.data);
  };

  useEffect(() => {
    fetchRewardData();
  }, []);

  console.log("Reward points", rewardPoints);

  const handleUpdate = async (e) => {
    e.preventDefault();
    const updateData = {
      priceValue,
      pointValue,
      vendorPointPer,
      shoppersPointPer,
    };

    // Add your update logic here, e.g., sending a request to your backend API

    let data = await updateRewardPoint(updateData, token);
    console.log("Updating with data:", data);
  };

  return (
    <div>
      <TopNav title="Slider Management" />
      <div className="w-[1160px]">
        <section className="w-[800px] ml-[150px] flex flex-row items-center justify-center py-0 px-6 box-border text-left text-7xl text-darkturquoise font-gilroy">
          <div className="w-[600px] flex-1 shadow-[0px_4px_21px_12px_rgba(0,_0,_0,_0.05)] rounded-sm bg-white flex flex-col items-start justify-start pt-7 px-7 pb-[27px] box-border gap-[30px]">
            <h3 className="m-0 h-[22px] relative text-inherit text-black leading-[26px] capitalize font-bold font-inherit inline-block shrink-0 z-[1] mq450:text-2xl mq450:leading-[21px]">
              Update Reward Value
            </h3>
            <form
              className="m-0 w-[755px] flex flex-col items-start justify-start gap-[20px] max-w-full"
              onSubmit={handleUpdate}
            >
              <div className="self-stretch flex flex-row items-start justify-start gap-[24px] max-w-full mq750:flex-wrap">
                <div className="flex-1 flex flex-col items-start justify-start gap-[20px] min-w-[338px] max-w-full mq750:min-w-full">
                  <div>
                    <label>Reward Value</label>
                    <div className="flex items-center space-x-4">
                      <div className="flex-1 rounded-lg box-border flex flex-row items-center justify-start py-5 px-[22px] max-w-full z-[1] border-[1px] border-solid border-silver">
                        <input
                          className="w-full border-none outline-none font-gilroy text-base bg-transparent leading-[16px] capitalize text-darkgray-200 text-left"
                          placeholder="Point Value"
                          type="number"
                          value={pointValue}
                          onChange={(e) => setPointValue(e.target.value)}
                        />
                      </div>
                      <span>=</span>
                      <div className="relative flex-1 rounded-lg box-border flex flex-row items-center justify-start py-5 px-[22px] max-w-full z-[1] border-[1px] border-solid border-silver">
                        <span className="absolute left-4 top-1/2 transform -translate-y-1/2 text-darkgray-200">
                          $
                        </span>
                        <input
                          className="pl-8 w-full border-none outline-none font-gilroy text-base bg-transparent leading-[16px] capitalize text-darkgray-200 text-left"
                          placeholder="Price Value"
                          type="number"
                          value={priceValue}
                          onChange={(e) => setPriceValue(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <label>Vendor Point Percentage</label>
                    <div className="self-stretch rounded-lg box-border flex flex-row items-start justify-start py-5 px-[22px] max-w-full z-[1] border-[1px] border-solid border-silver">
                      <input
                        className="w-full border-none outline-none font-gilroy text-base bg-transparent relative leading-[16px] capitalize text-darkgray-200 text-left flex items-center p-0 z-[2]"
                        placeholder="Vendor Point Percentage"
                        type="number"
                        value={vendorPointPer}
                        onChange={(e) => setVendorPointPer(e.target.value)}
                      />
                    </div>
                  </div>
                  <div>
                    <label>Shoppers Point Percentage</label>
                    <div className="self-stretch rounded-lg box-border flex flex-row items-start justify-start py-5 px-[22px] max-w-full z-[1] border-[1px] border-solid border-silver">
                      <input
                        className="w-full border-none outline-none font-gilroy text-base bg-transparent relative leading-[16px] capitalize text-darkgray-200 text-left flex items-center p-0 z-[2]"
                        placeholder="Shoppers Point Percentage"
                        type="number"
                        value={shoppersPointPer}
                        onChange={(e) => setShoppersPointPer(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[632px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full">
                <button
                  className="cursor-pointer py-4 px-[59px] bg-darkturquoise w-[162px] rounded-lg box-border flex flex-row items-start justify-start z-[1] border-[1px] border-solid border-darkturquoise hover:bg-cadetblue-100 hover:box-border hover:border-[1px] hover:border-solid hover:border-cadetblue-100"
                  type="submit"
                >
                  <div className="flex-1 relative text-lg leading-[18px] capitalize font-gilroy text-white text-center">
                    Save
                  </div>
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>
    </div>
  );
};

export default UpdateRewardPoints;
