import React from "react";
import TopNav from "../../../Components/TopNav/TopNav";
import SliderManagementData from "./SliderManagemntData";

const SliderManagemnt = () => {
  return (
    <div className="w-full">
      <TopNav title="Slider Managemnt" />
      <SliderManagementData />
    </div>
  );
};

export default SliderManagemnt;
