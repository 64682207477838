import React from 'react';
import TopNav from '../../../Components/TopNav/TopNav';
import RewardManagementData from './RewardManagementData';

const RewardManagement = () => {
  return (
    <div className='w-full'>
      <TopNav title='Reward Management' /> 
      <RewardManagementData />
    </div>
  );
};

export default RewardManagement;
