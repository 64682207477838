import React, { useState } from "react";
import { Link } from "react-router-dom";
import tshir from "../../../Assets/img/tshrit.svg";
import CategoryBottunSlider from "./CategoryBottunSlider";
import GetRewards from "./GetRewards";

const RewardManagementData = () => {
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  console.log("RewardManagementData", selectedCategoryId)

  return (
    <div>
      <section class="w-full self-stretch px-5 flex flex-col items-end justify-start  py-0 box-border gap-[20px] max-w-full text-left text-9xl text-gray-100 font-gilroy">
        <div class="self-stretch flex flex-col items-start justify-start gap-[15px] max-w-full">
      

          <div class="self-stretch flex flex-row items-start justify-start max-w-full">
            <div class="w-full flex flex-row flex-wrap items-start justify-start py-0 pr-0 box-border gap-[20px] max-w-full">
              <Link
                to="/RewardManagement/rewardsPoint"
                class="cursor-pointer [border:none] pt-[19px] px-5 pb-5 bg-darkturquoise flex-1 shadow-[0px_4px_21px_12px_rgba(0,_0,_0,_0.05)] rounded-3xs flex flex-row items-start justify-center box-border min-w-[136px] max-w-full hover:bg-cadetblue-100"
              >
                <div class=" relative text-lg leading-[18px] capitalize font-gilroy text-white text-center inline-block shrink-0 z-[1]">
                  Reward's Point{" "}
                </div>
              </Link>
              <Link
                to="/RewardManagement/rewardsStatus"
                class="cursor-pointer [border:none] pt-[19px] px-5 pb-5 bg-darkturquoise flex-1 shadow-[0px_4px_21px_12px_rgba(0,_0,_0,_0.05)] rounded-3xs flex flex-row items-start justify-center box-border min-w-[136px] max-w-full hover:bg-cadetblue-100"
              >
                <div class=" relative text-lg leading-[18px] capitalize font-gilroy text-white text-center inline-block shrink-0 z-[1]">
                  reward's status{" "}
                </div>
              </Link>
              <Link
                to="/RewardManagement/addCategory"
                class="cursor-pointer [border:none] pt-[19px] px-5 pb-5 bg-darkturquoise flex-1 shadow-[0px_4px_21px_12px_rgba(0,_0,_0,_0.05)] rounded-3xs flex flex-row items-start justify-center box-border min-w-[136px] whitespace-nowrap max-w-full hover:bg-cadetblue-100"
              >
                <div class=" relative shadow-[0px_4px_21px_12px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-darkturquoise hidden max-w-full"></div>
                <a class="[text-decoration:none] w-[124px] relative text-lg leading-[18px] capitalize font-gilroy text-white text-center inline-block shrink-0 z-[1]">
                  add category
                </a>
              </Link>
              <Link
                to="/RewardManagement/addReward"
                class="cursor-pointer [border:none] pt-[19px] px-5 pb-5 bg-darkturquoise flex-1 shadow-[0px_4px_21px_12px_rgba(0,_0,_0,_0.05)] rounded-3xs flex flex-row items-start justify-center box-border min-w-[136px] whitespace-nowrap max-w-full hover:bg-cadetblue-100"
              >
                <div class=" relative text-lg leading-[18px] capitalize font-gilroy text-white text-center inline-block shrink-0 min-w-[78px] z-[1]">
                  add reward
                </div>
              </Link>
            </div>
          </div>
        </div>
        <CategoryBottunSlider setSelectedCategoryId={setSelectedCategoryId} />
        <GetRewards selectedCategoryId={selectedCategoryId} />
      </section>
    </div>
  );
};

export default RewardManagementData;
