import React, { useEffect, useState } from "react";
import trashBlack from "../../../Assets/img/trashBlack.svg";
import UploadImage from "../../../Assets/img/UploadImage.svg";
import {
  getSlides,
  deleteSlide,
  updateSlide,
} from "../../../Utils/HelperFunctions";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const SliderManagementData = () => {
  const [images, setImages] = useState([]);
  const [draggedItemIndex, setDraggedItemIndex] = useState(null);
  const [oldSlideNo, setOldSlideNo] = useState(null);
  const [newSlideNo, setNewSlideNo] = useState(null);
  const token = useSelector((state) => state?.Auth.user.data.token);

  const fetchSlides = async () => {
    let data = await getSlides(token);
    setImages(data);
  };

  useEffect(() => {
    fetchSlides();
  }, []);

  const handleDragStart = (e, index) => {
    setDraggedItemIndex(index);
  };

  const handleDragOver = async (e, index, slideNo) => {
    e.preventDefault();

    if (draggedItemIndex === null || draggedItemIndex === index) return;

    const updatedImages = [...images];
    const draggedItem = updatedImages[draggedItemIndex];

    // Remove the dragged item from its original position
    updatedImages.splice(draggedItemIndex, 1);

    // Insert the dragged item at the new position
    updatedImages.splice(index, 0, draggedItem);

    // Update the state with the new positions
    setDraggedItemIndex(index);
    setImages(updatedImages);
  };

  const updateSlideData = async (slideId) => {
    try {
      // Prepare the payload with the new and old slide numbers
      const payload = {
        newNumber: newSlideNo, // The new position of the dragged item (index is 0-based, so add 1)
        oldNumber: oldSlideNo, // The original slide number
      };

      // Send the update request to the server
      await updateSlide(token, slideId, payload);

      // Refresh the slides to get the updated order from the server
      fetchSlides();
    } catch (error) {
      console.error("Failed to update slides order:", error);
    }
  };

  const handleDragEnd = async (e, index, slideId, slideNo) => {
    console.log(index, "indexxxx");
    if (draggedItemIndex === null || draggedItemIndex === index) return;

    const newSlideIndex = index + 1; // Assuming the index corresponds to the new slide number

    if (!slideId) {
      console.error("Invalid slideId:", slideId);
      return;
    }

    updateSlideData(slideId);

    setDraggedItemIndex(null);
  };

  const handleDelete = async (index) => {
    const slideId = images[index]._id;
    try {
      await deleteSlide(token, slideId);
      setImages(images.filter((_, idx) => idx !== index));
    } catch (error) {
      console.error("Failed to delete slide:", error);
    }
  };

  const handleToggle = async (index) => {
    const updatedSlide = {
      ...images[index],
      isPublished: !images[index].isPublished,
    };

    try {
      await updateSlide(token, updatedSlide._id, {
        isPublished: updatedSlide.isPublished,
      });
      setImages(
        images.map((image, idx) => (idx === index ? updatedSlide : image))
      );
    } catch (error) {
      console.error("Failed to update slide:", error);
    }
  };

  const sortedImages = [...images].sort(
    (a, b) => a.slideNumber - b.slideNumber
  );

  return (
    <div className="container mx-auto">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-4">
        {sortedImages.length > 0 ? (
          sortedImages.map((image, index) => (
            <div
              key={image._id}
              className="max-w-sm bg-white border border-gray-200 rounded-lg shadow-md overflow-hidden"
              draggable
              onDragStart={(e) => {
                setOldSlideNo(image.slideNumber); // Set the old slide number when dragging starts
                handleDragStart(e, index);
              }}
              onDragOver={(e) => {
                setNewSlideNo(index + 1); // Set the new slide number during drag over
                handleDragOver(e, index);
              }}
              onDragEnd={(e) => {
                handleDragEnd(e, index, image._id, image.slideNumber);
              }}
              style={{
                transform:
                  draggedItemIndex === index ? "scale(1.05)" : "scale(1)",
                transition: "transform 0.2s ease-in-out",
              }}
            >
              <img
                className="w-full h-48 object-cover"
                src={`https://images-backend.s3.amazonaws.com/${image.images[0].file}`}
                alt={`Image ${image._id}`}
              />
              <div className="p-4">
                <h5 className="text-2xl font-bold tracking-tight text-gray-900 mb-2">
                  {image.title}
                </h5>
                <p className="text-gray-700 dark:text-gray-400 mb-4">
                  {image.description}
                </p>
                <div className="flex items-center justify-between mb-4">
                  <label
                    htmlFor={`toggle-${index}`}
                    className="flex items-center cursor-pointer"
                  >
                    <div className="relative">
                      <input
                        id={`toggle-${index}`}
                        type="checkbox"
                        className="sr-only"
                        checked={image.isPublished}
                        onChange={() => handleToggle(index)}
                      />
                      <div className="block bg-gray-300 w-14 h-8 rounded-full"></div>
                      <div
                        className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition ${
                          image.isPublished
                            ? "transform translate-x-full bg-green-500"
                            : ""
                        }`}
                      ></div>
                    </div>
                    <span className="ml-3 text-gray-700 font-medium">
                      {image.isPublished ? "Published" : "Not Published"}
                    </span>
                  </label>
                </div>
                <button
                  onClick={() => handleDelete(index)}
                  className="w-full flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                >
                  Delete
                  <img src={trashBlack} alt="Delete" className="ml-2 w-4 h-4" />
                </button>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center col-span-3">No slides available.</p>
        )}
      </div>
      <Link
        to="/addSlide"
        className="mt-4 ml-8 inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-primary rounded-lg  focus:ring-4 focus:outline-none focus:ring-blue-300"
      >
        <img
          src={UploadImage}
          className="inline-block max-h-full max-w-full mr-2"
          alt="Upload"
        />
        Upload New Image
      </Link>
    </div>
  );
};

export default SliderManagementData;
