import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify"; // Ensure you have react-toastify installed
import { changePassword } from "../../Utils/HelperFunctions";

const ChangePasswordModal = ({ show, handleClose }) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const token = useSelector((state) => state?.Auth.user.data.token);
  const userId = useSelector((state) => state?.Auth.user.data.user._id);

  const handlePasswordChange = async () => {
    if (newPassword === confirmPassword) {
      try {
        const data = {
          oldPassword: currentPassword,
          newPassword: newPassword,
        };

        const response = await changePassword(token, data, userId);

        // Handle success
        toast.success("Password changed successfully!");
        handleClose(); // Close the modal on success
      } catch (error) {
        // Handle error
        toast.error("Failed to change password. Please try again.");
      }
    } else {
      toast.error("New passwords do not match.");
    }
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      {/* Blurred Background */}
      <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-0">
        {/* Modal Content */}
        <div className="relative z-10 top-32 p-6 max-w-md mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
          <Modal.Header closeButton className="border-b border-gray-200">
            <Modal.Title className="text-xl font-semibold">
              Change Password
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="currentPassword" className="mb-4">
                <Form.Label className="block text-gray-700 font-medium">
                  Current Password
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter current password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                />
              </Form.Group>

              <Form.Group controlId="newPassword" className="mb-4">
                <Form.Label className="block text-gray-700 font-medium">
                  New Password
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter new password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                />
              </Form.Group>

              <Form.Group controlId="confirmPassword" className="mb-4">
                <Form.Label className="block text-gray-700 font-medium">
                  Confirm New Password
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirm new password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer className=" border-gray-200">
            <Button
              variant="secondary"
              onClick={handleClose}
              className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600"
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handlePasswordChange}
              className="px-4 py-2 ml-2 bg-primary text-white rounded-md hover:bg-blue-600"
            >
              Change Password
            </Button>
          </Modal.Footer>
        </div>
      </div>
    </Modal>
  );
};

export default ChangePasswordModal;
