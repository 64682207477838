import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ArticleImg from "../../../Assets/img/article.png";
import { addBlog, handleBlogImage } from "../../../Utils/HelperFunctions";
import EditorModel from "../../../Components/Modal/EditorModel";

const Article = () => {
  const [article, setArticle] = useState({
    title: "",
    description: "",
  });
  const [description, setDescription] = useState("Add Description");
  const [uploadedImage, setUploadedImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const token = useSelector((state) => state?.Auth.user.data.token);

console.log(uploadedImage);

  const handleChange = (e) => {
    setArticle((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async () => {
    if (
      article.title.trim() === "" ||
      description === "Add Description" ||
      uploadedImage == null
    ) {
      toast.error("Please fill in all required fields");
    } else {
      console.log( uploadedImage );
      const formData = {
        title: article.title,
        description: description,
        images: [{ file: uploadedImage }],
      };
      console.log( formData );
      try {
        const data = await addBlog(formData, token);
        console.log(data);
        setArticle({
          title: "",
          description: "",
        });
        setDescription("Add Description");
        setUploadedImage(null);
        toast.success("Article submitted successfully");
      } catch (error) {
        console.error(error);
        toast.error("Failed to submit article");
      }
    }
  };

  

  const handleFile = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const formData = new FormData();
        formData.append("file", file);
        const data = await handleBlogImage(formData, token);
        console.log(data);
        if (data && data.file) {
          // Assuming 'data.file' contains the correct file name or path
          setUploadedImage(`https://images-backend.s3.amazonaws.com/${data.file}`);
        }
      } catch (error) {
        console.error(error);
        toast.error("Failed to upload image");
      }
    }
  };
  

  return (
    <div className="w-full lg:w-[483px] h-[379px] bg-white m-auto rounded-[15px] p-4 responsive-inner-container">
      <p className="text-base text-[#121212] font-g-bold p-4 line-height-[28px] md:text-[24px]">
        Article
      </p>
      <div className="w-[162px] h-[98px] bg-[#F2F2F2] rounded-lg py-2 flex flex-col items-center justify-center">
        <label
          htmlFor="file"
          className="text-gray-500 cursor-pointer mb-2 flex items-center justify-center"
        >
          <img
            src={uploadedImage !== null ? uploadedImage : ArticleImg}
            alt="Article"
            className="w-[44.69px] h-[37.81px] flex items-center justify-center"
          />
        </label>
        <input
          type="file"
          name="file"
          id="file"
          onChange={handleFile}
          className="file-input w-full h-[35px] lg:w-[988px] rounded-[5px] font-g-medium text-base line-height-[19px] text-[#A2A2A2] mt-2 focus:outline-blue-500 responsive-container"
        />
      </div>
      <input
        type="text"
        name="title"
        id="title"
        value={article.title}
        onChange={handleChange}
        placeholder="Text"
        className="w-full h-[32px] lg:w-[432px] border border-[#A2A2A2] rounded-[5px] px-4 font-g-medium text-base line-height-[19px] text-[#A2A2A2] mt-2 focus:outline-blue-500 responsive-container"
      />
      <p
        onClick={() => setShowModal(true)}
        dangerouslySetInnerHTML={{ __html: description }}
        className="w-full h-[89px] overflow-hidden lg:w-[432px] cursor-pointer border border-[#A2A2A2] rounded-[5px] px-4 font-g-medium text-base line-height-[19px] text-[#A2A2A2] mt-2 focus:outline-blue-500 responsive-container"
      />
      <button
        className="w-[77px] h-[31px] bg-primary text-white rounded-[5px] mt-2 text-[15px] font-g-regular line-height-[18px]"
        onClick={handleSubmit}
      >
        Submit
      </button>
      {showModal && (
        <EditorModel
          showModal={showModal}
          setShowModal={setShowModal}
          setDescription={setDescription}
        />
      )}
    </div>
  );
};

export default Article;
