import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import pdfIcon from "../../../Assets/img/pdf icon.svg";
import TopNav from "../../../Components/TopNav/TopNav";
import {
  getRewardHistory,
  updateRewardHistoryStatus,
} from "../../../Utils/HelperFunctions";
import RewardStatusDropdown from "./RewardStatusDropdown";
const RewardsStatus = () => {
  const token = useSelector((state) => state?.Auth.user.data.token);
  const [data, setData] = useState(null);

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const getCategory = async () => {
    let data = await getRewardHistory(token);
    console.log(token);
    console.log(data.data);
    setData(data.data);
  };

  useEffect(() => {
    getCategory();
  }, []);

  const updateRewardStatus = async () => {
    // let data = await updateRewardHistoryStatus(token);
    // // console.log(data.data);
    // setData(data.data);
  };

  return (
    <div className="w-full dashboard ">
      <TopNav title="Rewards Management" />
      <section className="w-full  flex flex-row items-start justify-end py-0 px-7 box-border max-w-full text-left text-9xl text-gray-100 font-gilroy">
        <div className="flex-1 shadow-[0px_4px_21px_12px_rgba(0,_0,_0,_0.05)] rounded-3xs bg-white flex flex-col items-start justify-start pt-[31px] pb-10 pr-0 pl-[3px] box-border gap-[15px] max-w-full mq750:pt-5 mq750:pb-[26px] mq750:box-border">
          <div className="self-stretch flex flex-col items-start justify-start  ">
            <div className="flex border-b w-full pb-6 flex-row items-start justify-start py-0 px-7">
              <b className="relative leading-[24px] font-semibold inline-block min-w-[111px] z-[1] mq450:text-3xl mq450:leading-[22px]">
                Rewards
              </b>
            </div>
            <img
              className="self-stretch relative max-w-full overflow-hidden max-h-full z-[1]"
              alt=""
              src="/public/vector-12.svg"
            />
          </div>
          <div className="w-full flex flex-col items-start justify-start py-0 px-[38px] box-border max-w-full text-lg ">
            <ul className="w-full flex mb-5 flex-row items-start justify-between py-2  text-darkturquoise relative leading-[18px] capitalize font-bold inline-block z-[1] mr-6">
              <li>Reward Name</li>
              <li>Vendo Name</li>
              <li>Vendo Email</li>
              <li>Date</li>
              <li>Attechments</li>
              <li className="mr-10">Status</li>
            </ul>

            <ul className="w-full flex text-black flex-col items-start justify-start gap-2">
              {data?.length > 0 ? (
                data.map((reward, index) => (
                  <li
                    key={index}
                    className="w-full flex flex-row items-center justify-between py-0"
                  >
                    <div className=" w-1/5">
                      <p className="relative leading-[18px] capitalize z-[1]">
                        {reward.rewardProductId.name}
                      </p>
                    </div>
                    <div className="flex flex-col items-center justify-center w-1/5">
                      <p className="relative capitalize inline-block z-[1]">
                        {reward.userId.name}
                      </p>
                    </div>
                    <div className="flex flex-col items-center justify-center w-1/5">
                      <p className="relative capitalize inline-block z-[1]">
                        {reward.userId.email}
                      </p>
                    </div>

                    <div className="flex flex-col items-center justify-center w-1/5">
                      <p className="relative inline-block z-[1]">
                        {new Date(reward.createdAt).toLocaleDateString()}
                      </p>
                    </div>
                    {reward.image_url ? (
                      <div className="flex flex-row items-center justify-center w-1/5 gap-[11px]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="darkturquoise"
                          className="bi bi-file-earmark-image cursor-pointer"
                          viewBox="0 0 16 16"
                          onClick={handleOpenModal}
                        >
                          <path d="M6.502 7a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                          <path d="M14 14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zM4 1a1 1 0 0 0-1 1v10l2.224-2.224a.5.5 0 0 1 .61-.075L8 11l2.157-3.02a.5.5 0 0 1 .76-.063L13 10V4.5h-2A1.5 1.5 0 0 1 9.5 3V1z" />
                        </svg>
                        <div className="relative leading-[18px] capitalize inline-block z-[1]">
                          Attachments
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-row items-center justify-center w-1/5 gap-[11px]">
                        <div className="relative leading-[18px] capitalize inline-block z-[1]">
                          No Attachments
                        </div>
                      </div>
                    )}

                    {showModal && (
                      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="bg-white p-4 rounded-lg shadow-lg">
                          <img
                            src={reward.image_url}
                            alt="Attachment"
                            className="max-w-full max-h-[80vh] object-contain"
                          />
                          <div className="flex justify-between mt-4">
                            <button
                              className="bg-red-500 text-white px-4 py-2 rounded-lg"
                              onClick={handleCloseModal}
                            >
                              Close
                            </button>
                            <a
                              href={reward.image_url}
                              target="_blank"
                              rel="noopener noreferrer"
                              download
                              className="bg-darkturquoise text-white px-4 py-2 rounded-lg"
                            >
                              Download
                            </a>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="w-1/5 flex justify-end">
                      <RewardStatusDropdown
                        reward={reward}
                        status={reward.status}
                        categoryType={
                          reward.rewardProductId.categoryType.categoryType
                        }
                        updateRewardStatus={updateRewardStatus}
                      />
                    </div>
                  </li>
                ))
              ) : (
                <p>No rewards available.</p>
              )}
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RewardsStatus;
