import { toast } from "react-toastify";
import Axios from "../Axios/Axios";
import { config } from "./config";

export const getFaqs = async () => {
  try {
    const response = await Axios.get("/cms?filters=type:FAQ");

    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error.response.data.message);
  }
};

// Get Help Request Data
export const getHelpRequest = async () => {
  try {
    const response = await Axios.get("/support");

    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error.response.data.message);
  }
};

// AddComment
export const AddComment = async (id, message, token) => {
  try {
    const response = await Axios({
      method: "PUT",
      url: `support/${id}/add-comment`,
      data: {
        message: message,
      },
      headers: {
        authorization: token,
      },
    });
    toast.success("Comment added successfully");
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error.response.data.message);
  }
};

// Add Blog Article
export const addBlog = async (data, token) => {
  console.log(data);
  try {
    const response = await Axios({
      method: "POST",
      url: `/blogs`,
      data,
      headers: {
        authorization: token,
      },
    });
    console.log(response.data);
    toast.success("Blog added successfully");
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error.response.data.message);
  }
};

// Add FAQs
export const addFaqs = async (data, token) => {
  try {
    const response = await Axios({
      method: "POST",
      url: `/cms`,
      data: {
        title: data.title,
        body: data.body,
        type: "FAQ",
        images: [{ file: data.file }],
      },
      headers: {
        authorization: token,
      },
    });
    toast.success("FAQ added successfully");
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error.response.data.message);
  }
};

// Get CallBacks Data
export const getCallBacksData = async (token) => {
  try {
    const response = await Axios({
      method: "GET",
      url: `/callbackRequest`,
      headers: {
        authorization: token,
      },
    });

    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error.response.data.message);
  }
};

// Get Reported Bugs Data
export const getReportedBugsData = async (token) => {
  try {
    const response = await Axios({
      method: "GET",
      url: `/reportBugs`,
      headers: {
        authorization: token,
      },
    });

    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error.response.data.message);
  }
};

// Handle Blog Image Upload
export const handleBlogImage = async (formData, token) => {
  try {
    const response = await Axios({
      method: "POST",
      url: `/blogs/upload-images`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: token,
      },
    });
    toast.success(response.data.message);
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error.response.data.message);
  }
};

// Get All Users
export const getAllUsers = async (token) => {
  try {
    const response = await Axios({
      method: "GET",
      url: `/users`,
      headers: {
        authorization: token,
      },
    });

    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error.response.data.message);
  }
};

// Handle FAQ Image Upload
export const handleFaqImage = async (formData, token) => {
  try {
    const response = await Axios({
      method: "POST",
      url: `/cms/upload-images`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: token,
      },
    });
    toast.success(response.data.message);
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error.response.data.message);
  }
};

// Get Orders Data
export const getOrders = async (token) => {
  try {
    const response = await Axios({
      method: "GET",
      url: "/admin/orders",
      headers: {
        authorization: token,
      },
    });

    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error.response.data.message);
  }
};

// Get Category List
export const getCategoryList = async (token) => {
  try {
    const response = await Axios({
      method: "GET",
      url: "/admin/get-category-list",
      headers: {
        authorization: token,
      },
    });

    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error.response.data.message);
  }
};

export const addCategory = async (data, token) => {
  try {
    const response = await Axios({
      method: "POST",
      url: "/admin/add-category-list",
      data: data,
      headers: {
        authorization: token,
      },
    });
    toast.success("Category added successfully");
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error.response.data.message);
  }
};

// Get Rewards Data
export const getRewards = async (token) => {
  try {
    const response = await Axios({
      method: "GET",
      url: "admin/get-reward-management",
      headers: {
        authorization: token,
      },
    });

    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error.response.data.message);
  }
};

// Delete a Category
export const deleteCategory = async (token, categoryId) => {
  try {
    const response = await Axios({
      method: "DELETE",
      url: `admin/remove-category-list/${categoryId}`,
      headers: {
        authorization: token,
      },
    });
    toast.success("Category deleted successfully");
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error.response.data.message);
  }
};

// Get Reward Data
export const getRewardData = async (token, rewardId) => {
  try {
    const response = await Axios({
      method: "GET",
      url: `admin/get-reward-management/${rewardId}`,
      headers: {
        authorization: token,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Failed to fetch reward data:", error);
    toast.error(error.response.data.message);
  }
};

// Add Reward Data
export const addRewardData = async (token, FormData) => {
  try {
    const response = await Axios({
      method: "POST",
      url: "admin/add-reward-management", // Replace with your actual API endpoint
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: token,
      },
      data: FormData,
    });
    toast.success("Reward data added successfully");
    return response.data; // Return the response data
  } catch (error) {
    console.error("Failed to add reward data:", error);
    toast.error(error.response.data.message);
    throw error; // Re-throw the error to handle it in the caller function
  }
};

// Get Reward Data by Category
export const getRewardByCategory = async (token, categoryId) => {
  try {
    const response = await Axios({
      method: "GET",
      url: `admin/get-reward-management-data-by-category-id/${categoryId}`,
      headers: {
        authorization: token,
      },
    });

    return response.data.data;
  } catch (error) {
    console.error("Failed to fetch reward data:", error);
    toast.error(error.response.data.message);
  }
};

// Get Reward Points
export const getRewardPoint = async (token) => {
  try {
    const response = await Axios({
      method: "GET",
      url: "admin/get-reward-point",
      headers: {
        authorization: token,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Failed to fetch reward data:", error);
    toast.error(error.response.data.message);
  }
};

// Update Reward Points
export const updateRewardPoint = async (data, token) => {
  try {
    const response = await Axios({
      method: "PUT",
      url: "admin/update-reward-point/668b814fb70e362f7511e804",
      data: data,
      headers: {
        authorization: token,
      },
    });
    toast.success("Reward points updated successfully");
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error.response.data.message);
  }
};

// Update Reward Data
export const updateRewardDetails = async (rewardId, data, token) => {
  console.log("updateRewardDetails-------->", rewardId, data, token);
  try {
    const response = await Axios({
      method: "PUT",
      url: `admin/update-reward-management/${rewardId}`,
      data: data,
      headers: {
        authorization: token,
      },
    });
    console.log("Response:", response);

    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error.response.data.message);
  }
};

export const getRewardHistory = async (token) => {
  try {
    const response = await Axios({
      method: "GET",
      url: "admin/get-reward-history",
      headers: {
        authorization: token,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Failed to fetch reward data:", error);
    toast.error(error.response.data.message);
  }
};

export const deleteReward = async (rewardId, token) => {
  try {
    const response = await Axios({
      method: "DELETE",
      url: `admin/remove-reward-management/${rewardId}`,
      headers: {
        authorization: token,
      },
    });
    toast.success("Reward deleted successfully");

    return response.data;
  } catch (error) {
    console.error("Failed to delete reward:", error);
    toast.error(error.response.data.message);
  }
};

export const updateRewardData = async (token, FormData, rewardId) => {
  try {
    const response = await Axios({
      method: "POST",
      url: `admin/update-reward-management/${rewardId}`, // Replace with your actual API endpoint
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: token,
      },
      data: FormData,
    });
    toast.success("Update data added successfully");
    return response.data; // Return the response data
  } catch (error) {
    console.error("Failed to add reward data:", error);
    toast.error(error.response.data.message);
    throw error; // Re-throw the error to handle it in the caller function
  }
};

export const getBlogs = async (token) => {
  try {
    const response = await Axios({
      method: "GET",
      url: "blogs",
      headers: {
        authorization: token,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Failed to fetch reward data:", error);
    toast.error(error.response.data.message);
  }
};

export const getFAQ = async (token) => {
  try {
    const response = await Axios({
      method: "GET",
      url: "blogs",
      headers: {
        authorization: token,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Failed to fetch reward data:", error);
    toast.error(error.response.data.message);
  }
};

export const updateRewardHistoryStatus = async (rewardId, data, token) => {
  console.log("updateRewardDetails-------->", rewardId, data, token);

  try {
    const response = await Axios({
      method: "PUT",
      url: `admin/update-status-reward-history/${rewardId}`,
      data: data,
      headers: {
        authorization: token,
        "Content-Type": "application/json",
      },
    });

    console.log("Response:", response);

    // Show success toast notification
    toast.success("Reward status updated successfully!");

    return response.data;
  } catch (error) {
    console.log("Error:", error);

    // Show error toast notification
    if (error.response && error.response.data && error.response.data.message) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Failed to update reward status. Please try again.");
    }
  }
};

// Delete a Category
export const deleteArticle = async (token, articleID) => {
  console.log("deleteArticle-------->", articleID);
  try {
    const response = await Axios({
      method: "DELETE",
      url: `blogs/${articleID}`,
      headers: {
        authorization: token,
      },
    });
    toast.success("Article deleted successfully");
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error.response.data.message);
  }
};

export const getSlides = async (token) => {
  try {
    const response = await Axios({
      method: "GET",
      url: "get-slide",
      headers: {
        authorization: token,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Failed to fetch reward data:", error);
    toast.error(error.response.data.message);
  }
};

// Get Reward Data
export const getArticle = async (token, rewardId) => {
  try {
    const response = await Axios({
      method: "GET",
      url: `admin/get-reward-management/${rewardId}`,
      headers: {
        authorization: token,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Failed to fetch reward data:", error);
    toast.error(error.response.data.message);
  }
};

// Update Article
export const updateArticle = async (token, articleId, data) => {
  console.log(`updateArticle`, articleId);
  try {
    const response = await Axios({
      method: "PUT",
      url: `blogs/${articleId}`, // Replace with your actual base URL
      headers: {
        Authorization: token, // Use 'Bearer' prefix if needed
      },
      data: data, // Include data in the request body
    });

    return response.data;
  } catch (error) {
    console.error("Failed to update article:", error);

    // Check if error.response exists and handle accordingly
    if (error.response && error.response.data && error.response.data.message) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An unknown error occurred.");
    }
  }
};

export const addSliderImage = async (token, data) => {
  console.log(`addSliderImage`, data);
  try {
    const response = await Axios({
      method: "POST",
      url: "cms/upload-images", // Replace with your actual API endpoint
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: token, // Ensure token is prefixed with 'Bearer '
      },
      data: data, // Pass data directly
    });

    return response.data; // Return the response data
  } catch (error) {
    console.error("Failed to add image data:", error);
    // Check if error.response and error.response.data exist
    toast.error(error.response?.data?.message || "An error occurred");
    throw error; // Re-throw the error to handle it in the caller function
  }
};

export const addSlide = async (token, data) => {
  try {
    const response = await Axios({
      method: "POST",
      url: `cms`, // Replace with your actual base URL
      headers: {
        Authorization: token, // Use 'Bearer' prefix if needed
      },
      data: data, // Include data in the request body
    });

    return response.data;
  } catch (error) {
    console.error("Failed to update article:", error);

    // Check if error.response exists and handle accordingly
    if (error.response && error.response.data && error.response.data.message) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An unknown error occurred.");
    }
  }
};

// Delete a Slide
export const deleteSlide = async (token, slideId) => {
  try {
    const response = await Axios({
      method: "DELETE",
      url: `cms/${slideId}`, // Ensure this URL is correct
      headers: {
        Authorization: token,
      },
    });
    toast.success("Slide deleted successfully");
    return response.data;
  } catch (error) {
    console.error("Failed to delete slide:", error);

    // Check if error.response exists and handle accordingly
    if (error.response && error.response.data && error.response.data.message) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An unknown error occurred.");
    }
  }
};

// Update Slide
export const updateSlide = async (token, slideId, data) => {
  console.log(`updateSlide`, token, slideId, data);
  try {
    const response = await Axios({
      method: "PUT",
      url: `update-slide/${slideId}`, // Replace with your actual base URL for slides
      headers: {
        Authorization: token, // Use 'Bearer' prefix if needed
      },
      data: data, // Include data in the request body
    });

    return response.data;
  } catch (error) {
    console.error("Failed to update slide:", error);

    // Check if error.response exists and handle accordingly
    if (error.response && error.response.data && error.response.data.message) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An unknown error occurred.");
    }
  }
};

// Update Reward
export const updateReward = async (token, rewardId, data) => {
  console.log(`updateReward`, token, rewardId, data);
  try {
    const response = await Axios({
      method: "PUT",
      url: `admin/update-reward-management/${rewardId}`, // Use rewardId instead of slideId
      headers: {
        Authorization: token, // Add 'Bearer' prefix if needed
      },
      data: data, // Include data in the request body
    });

    console.log("Request", response.data);
    return response.data;
  } catch (error) {
    console.error("Failed to update reward:", error);

    // Check if error.response exists and handle accordingly
    if (error.response && error.response.data && error.response.data.message) {
      toast.error(error.response.data.message);
    } else {
      toast.error("An unknown error occurred.");
    }
  }
};

// Get Reported Bugs Data
export const membershipUsers = async (token) => {
  try {
    const response = await Axios({
      method: "GET",
      url: `/membership-users`,
      headers: {
        authorization: token,
      },
    });

    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error.response.data.message);
  }
};

export const deleteFaq = async (token, faqId) => {
  try {
    const response = await Axios({
      method: "DELETE",
      url: `cms/${faqId}`,
      headers: {
        Authorization: token,
      },
    });
    toast.success("Faq deleted successfully");
    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error.response.data.message);
  }
};

export const changePassword = async (token, data, userId) => {
  console.log("Change Password Request Data:", data);

  try {
    const response = await Axios({
      method: "POST",
      url: `/change-password/${userId}`, // Ensure this is the correct API endpoint
      headers: {
        "Content-Type": "application/json", // Typically, password change requests use JSON
        Authorization: `Bearer ${token}`, // Ensure the token is prefixed with 'Bearer '
      },
      data: data, // Pass the data directly
    });

    return response.data; // Return the response data
  } catch (error) {
    console.error("Failed to change password:", error);

    // Check if error.response and error.response.data exist
    toast.error(error.response?.data?.message || "An error occurred");

    throw error; // Re-throw the error to handle it in the caller function
  }
};

// Get getBusiness
export const getBusiness = async (token) => {
  try {
    const response = await Axios({
      method: "GET",
      url: `/business-types`,
      headers: {
        authorization: token,
      },
    });

    return response.data;
  } catch (error) {
    console.log(error);
    toast.error(error.response.data.message);
  }
};



export const addBusiness = async (token, data) => {


  try {
    const response = await Axios({
      method: "POST",
      url: `/business-types`, // Ensure this is the correct API endpoint
      headers: {
        "Content-Type": "application/json", // Typically, password change requests use JSON
        Authorization: token, // Ensure the token is prefixed with 'Bearer '
      },
      data: data, // Pass the data directly
    });

    return response.data; // Return the response data
  } catch (error) {
    console.error("Failed to change password:", error);

    // Check if error.response and error.response.data exist
    toast.error(error.response?.data?.message || "An error occurred");

    throw error; // Re-throw the error to handle it in the caller function
  }
};



export const updateCategoryforBusiness = async (token, categoryId, data) => {
  console.log(`updateCategoryforBusiness`, categoryId, data);
  try {
    const response = await Axios({
      method: "PUT",
      url: `business-types/${categoryId}`,
      headers: {
        Authorization: token, // Ensure Bearer token format
      },
      data: data, // Include updated data in the request body
    });
    toast.success("Category updated successfully!"); // Correct success message
    return response.data;
  } catch (error) {
    console.error("Error updating category:", error);
    toast.error(error.response?.data?.message || "Failed to update category");
  }
};

export const deleteCategoryforBusiness = async (token, categoryId) => {
  try {
    const response = await Axios({
      method: "DELETE",
      url: `business-types/${categoryId}`,
      headers: {
        Authorization: token, // Ensure Bearer token format
      },
    });
    toast.success("Category deleted successfully!"); // Clear success message
    return response.data;
  } catch (error) {
    console.error("Error deleting category:", error);
    toast.error(error.response?.data?.message || "Failed to delete category");
  }
};
