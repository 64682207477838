import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import TopNav from "../../../Components/TopNav/TopNav";
import cameraIcon from "../../../Assets/img/cameraIcon.svg";
import { addRewardData } from "../../../Utils/HelperFunctions";
import { getCategoryList } from "../../../Utils/HelperFunctions";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddRewardCopy = () => {
  const navigate = useNavigate();

  const token = useSelector((state) => state?.Auth.user.data.token);

  const [rewardName, setRewardName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [points, setPoints] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [specifications, setSpecifications] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");

  console.log("imageFile: " + imageFile);

  const [categories, setCategories] = useState([]); // Default categories

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const getCategory = async () => {
    let data = await getCategoryList(token);
    console.log(data.data);
    setCategories(data.data);
  };

  useEffect(() => {
    getCategory();
  }, []);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const addReward = async (e) => {
    e.preventDefault();

    console.log("Reward Name:", rewardName);
    console.log("Description:", description);
    console.log("Selected Category:", selectedCategory);
    console.log("Points:", points);
    console.log("Coupon Code:", couponCode);
    console.log("Specifications:", specifications);
    console.log("Image File:", imageFile);

    const formData = new FormData();
    formData.append("image", imageFile);
    formData.append("name", rewardName);
    formData.append("description", description);
    formData.append("categoryType", selectedCategory);
    formData.append("points", points);
    formData.append("couponCode", couponCode);
    formData.append("specification", specifications);

    for (let [key, value] of formData.entries()) {
      console.log(`${key}:::: ${value}`);
    }

    console.log("formData", formData);
    try {
      const response = await fetch(
        "http://54.90.106.142:3005/api/v1/admin/add-reward-management",
        {
          method: "POST",
          headers: {
            authorization: token,
          },
          body: formData,
        }
      );

      if (response.ok) {
        const jsonResponse = await response.json();
        console.log("Reward added successfully:", jsonResponse);
        toast.success("Reward added successfully!");
        setTimeout(() => navigate("/RewardManagement"), 2000); // Wait 2 seconds before navigating
      } else {
        console.error("Failed to add reward:", response.statusText);
        toast.error("Failed to add reward!");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      {" "}
      <ToastContainer />
      <TopNav title="Slider Management" />
      <section className="w-[1160px] flex flex-row items-start justify-end py-0 px-6 box-border max-w-full text-left text-7xl text-darkturquoise font-gilroy">
        <div className="flex-1 shadow-[0px_4px_21px_12px_rgba(0,_0,_0,_0.05)] rounded-sm bg-white flex flex-col items-start justify-start pt-7 px-7 pb-[27px] box-border gap-[30px] max-w-full mq750:pt-5 mq750:pb-5 mq750:box-border">
          <h3 className="m-0 h-[22px] relative text-inherit leading-[26px] capitalize font-bold font-inherit inline-block shrink-0 z-[1] mq450:text-2xl mq450:leading-[21px]">
            Add Reward
          </h3>
          <form
            className="m-0 w-[755px] flex flex-col items-start justify-start gap-[20px] max-w-full"
            onSubmit={addReward}
          >
            <div className="self-stretch flex flex-row items-start justify-start gap-[24px] max-w-full mq750:flex-wrap">
              <input
                type="file"
                accept="image/*"
                id="imageInput"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
              <div
                onClick={() => document.getElementById("imageInput").click()}
                className="cursor-pointer flex flex-col items-center justify-center"
              >
                {previewUrl ? (
                  <img
                    src={previewUrl}
                    alt="Preview"
                    className="h-[240px] w-[240px] relative z-[2] object-cover mq750:flex-1 shadow-[0px_0px_13px_rgba(0,_0,_0,_0.15)] rounded-3xs bg-lightcyan-100 flex flex-row items-start justify-start box-border min-w-[211px] z-[1] mq750:flex-1"
                  />
                ) : (
                  <div className="shadow-[0px_0px_13px_rgba(0,_0,_0,_0.15)] rounded-3xs bg-lightcyan-100 flex flex-row items-start justify-start pt-[83.1px] px-[79px] pb-[83px] box-border min-w-[211px] z-[1] mq750:flex-1">
                    <img
                      className="h-[44.9px] w-[51.6px] relative z-[2] mq750:flex-1"
                      loading="lazy"
                      alt="Camera Icon"
                      src={cameraIcon} // Replace with your default camera icon path
                    />
                  </div>
                )}
              </div>
              <div className="flex-1 flex flex-col items-start justify-start gap-[20px] min-w-[338px] max-w-full mq750:min-w-full">
                <div className="self-stretch rounded-lg box-border flex flex-row items-start justify-start py-5 px-[22px] max-w-full z-[1] border-[1px] border-solid border-silver">
                  <input
                    className="w-full border-none outline-none font-gilroy text-base bg-transparent relative leading-[16px] capitalize text-darkgray-200 text-left flex items-center p-0 z-[2]"
                    placeholder="Reward Name"
                    type="text"
                    value={rewardName}
                    onChange={(e) => setRewardName(e.target.value)}
                  />
                </div>
                <textarea
                  className="bg-transparent h-[137px] w-auto outline-none self-stretch rounded-lg box-border flex flex-row items-start justify-start p-[22px] font-gilroy text-base text-darkgray-200 z-[1] border-[1px] border-solid border-silver"
                  placeholder="Description"
                  spellCheck="true"
                  lang="en"
                  rows="7"
                  cols="26"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
                <div className="w-full self-stretch rounded-lg box-border flex flex-row items-start justify-between py-5 px-[21px] max-w-full z-[1] border-[1px] border-solid border-silver">
                  <select
                    className="w-full border-none outline-none font-gilroy text-base bg-transparent relative leading-[16px] capitalize text-darkgray-200 text-left flex items-center p-0 z-[2]"
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                  >
                    <option value="" disabled>
                      Select category
                    </option>
                    {Array.isArray(categories) &&
                      categories.map((category) => (
                        <option key={category._id} value={category._id}>
                          {category.name}
                        </option>
                      ))}
                  </select>
                  <div className="flex flex-col items-start justify-start pt-px px-0 pb-0">
                    <img
                      className="w-3.5 relative object-contain z-[2]"
                      alt=""
                      src="/public/vector-11.svg"
                    />
                  </div>
                </div>
                <div className="self-stretch rounded-lg box-border flex flex-row items-start justify-start py-5 px-[22px] max-w-full z-[1] border-[1px] border-solid border-silver">
                  <input
                    className="w-full border-none outline-none font-gilroy text-base bg-transparent relative leading-[16px] capitalize text-darkgray-200 text-left flex items-center p-0 z-[2]"
                    placeholder="Points"
                    type="text"
                    value={points}
                    onChange={(e) => setPoints(e.target.value)}
                  />
                </div>
                {selectedCategory === "6686477033d506af2c9f95db" && (
                  <div className="self-stretch rounded-lg box-border flex flex-row items-start justify-start py-5 px-[22px] max-w-full z-[1] border-[1px] border-solid border-silver">
                    <input
                      className="w-full border-none outline-none font-gilroy text-base bg-transparent relative leading-[16px] capitalize text-darkgray-200 text-left flex items-center p-0 z-[2]"
                      placeholder="Coupon Code"
                      type="text"
                      value={couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                    />
                  </div>
                )}

                <div className="self-stretch rounded-lg box-border flex flex-row items-start justify-start py-5 px-[22px] max-w-full z-[1] border-[1px] border-solid border-silver">
                  <input
                    className="w-full border-none outline-none font-gilroy text-base bg-transparent relative leading-[16px] normal-case text-darkgray-200 text-left flex items-center p-0 z-[2]"
                    placeholder="Specifications"
                    type="text"
                    value={specifications}
                    onChange={(e) => setSpecifications(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="w-[632px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full">
              <button
                className="cursor-pointer py-4 px-[59px] bg-darkturquoise w-[162px] rounded-lg box-border flex flex-row items-start justify-start z-[1] border-[1px] border-solid border-darkturquoise hover:bg-cadetblue-100 hover:box-border hover:border-[1px] hover:border-solid hover:border-cadetblue-100"
                type="submit"
              >
                <div className="flex-1 relative text-lg leading-[18px] capitalize font-gilroy text-white text-center">
                  Save
                </div>
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default AddRewardCopy;
