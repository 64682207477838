import React from "react";
import TopNav from "../../../Components/TopNav/TopNav";
import Article from "./Article";
import Giveaays from "./Giveaays";
import Vendors from "./Vendors";

const FeaturedVendors = () => {
  return (
    <div className="w-full">
      <TopNav title="Featured Vendors" />
      <div className="px-4 lg:px-0">
        <div className="container flex flex-col lg:flex-row gap-3 justify-center responsive-container">
          <Vendors />
  
        </div>
      </div>
    </div>
  );
};

export default FeaturedVendors;
