import React, { useState, useEffect } from "react";
import { updateRewardHistoryStatus } from "../../../Utils/HelperFunctions";
import { useSelector } from "react-redux";

const getStatusOptions = (categoryType) => {
  switch (categoryType) {
    case 0:
      return ["Claimed", "Initiated", "In transit", "Delivered", "Cancelled"];
    case 1:
      return ["Claimed", "Redeemed"];
    case 2:
      return ["Claimed", "Initiated", "In transit", "Delivered", "Cancelled"];
    case 3:
      return ["Claimed", "Initiated", "In progress", "Delivered", "Cancelled"];
    default:
      return [];
  }
};

const getStatusClassName = (status = "") => {
  switch (status.toLowerCase().replace(" ", "-")) {
    case "initiated":
      return "bg-lightpink hover:bg-lightcoral text-red-100";
    case "in-transit":
      return "bg-peachpuff hover:bg-burlywood text-chocolate";
    case "redeemed":
      return "bg-paleturquoise text-forestgreen";
    case "in-progress":
      return "bg-lightblue hover:bg-deepskyblue text-white";
    case "delivered":
      return "bg-paleturquoise text-forestgreen";
    case "cancelled":
      return "bg-lightgrey hover:bg-grey text-darkgrey";
    case "claimed":
      return "bg-lightpink hover:bg-lightcoral text-red-100";
    default:
      return "";
  }
};

const RewardStatusDropdown = ({
  reward,
  rewardStatus,
  categoryType,
  updateRewardStatus,
}) => {
  const token = useSelector((state) => state?.Auth.user.data.token);
  const [selectedStatus, setSelectedStatus] = useState("");

  console.log(
    "Props received - reward:", reward,
    "- rewardID:", reward._id,
    "rewardStatus:", rewardStatus,
    "categoryType:", categoryType,
    // "updateRewardStatus:", updateRewardStatus
  );

  useEffect(() => {
    setSelectedStatus(reward.status || "");
    console.log("Initial rewardStatus set to:", rewardStatus || "");
  }, [reward.status]);

  useEffect(() => {
    if (reward.status) {
      console.log("Selected status updated to:", reward.status);
      setSelectedStatus((prevStatus) => prevStatus);
    }
  }, [reward.status]);

  const handleStatusChange = async (e) => {
    const newStatus = e.target.value;
    setSelectedStatus(newStatus);
    updateRewardStatus(reward, newStatus);
    console.log("newStatus after selection:", newStatus);

    try {
      let data = await updateRewardHistoryStatus(
        reward._id,
        { status: newStatus },
        token
      );
      console.log("Response from updateRewardHistoryStatus API call:", data);
    } catch (error) {
      console.error("Error updating reward status:", error);
    }
  };

  return (
    <div className="reward-status">
      <select
        value={selectedStatus}
        onChange={handleStatusChange}
        className={`cursor-pointer [border:none] py-2.5 px-[19px] rounded-md flex flex-row items-start justify-center z-[1] ${getStatusClassName(
          selectedStatus
        )}`}
      >
        {getStatusOptions(categoryType).map((status) => (
          <option key={status} value={status}>
            {status}
          </option>
        ))}
      </select>
    </div>
  );
};

export default RewardStatusDropdown;
