import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Spinner from "../../../Components/spinner/Spinner";
import userImg from "../../../Assets/img/user.svg";
import { membershipUsers } from "../../../Utils/HelperFunctions";

const Vendors = () => {
  const [vendorsData, setVendorsData] = useState(null);
  const token = useSelector((state) => state?.Auth.user.data.token);

  useEffect(() => {
    const getUsersData = async () => {
      try {
        const data = await membershipUsers(token);
        setVendorsData(data);
      } catch (error) {
        console.error("Failed to fetch vendors data:", error);
      }
    };

    getUsersData(); // Call getUsersData function only once
  }, [token]); // Add token to dependency array to ensure effect runs correctly

  if (!vendorsData) {
    return <Spinner />;
  }

  return (
    <div className="w-full h-auto mx-4 bg-white md:mx-10 rounded-[15px] p-4">
      <p className="text-2xl text-[#121212] font-g-bold p-4 md:text-[24px]">
        Vendors
      </p>
      <div className="w-full h-[650px] overflow-y-scroll custom-scroll-bar">
        {vendorsData.result.length > 0 ? (
          vendorsData.result.map((data, index) => (
            <div
              className="bg-[#F2F2F2] w-full md:w-[95%] mx-auto min-h-[150px] flex flex-col justify-center px-4 mb-4 rounded-[10px] shadow-sm md:h-[89px] md:mb-4"
              key={index}
            >
              <div className="flex flex-col md:flex-row items-center justify-between">
                <div className="flex items-center">
                  <img
                    src={data.profilePicture || userImg}
                    className="bg-white w-[60px] h-[60px] rounded-full"
                    alt="Vendor"
                  />
                  <h3 className="text-base ml-4 text-[#000000] font-g-bold">
                    {data.businessName}
                  </h3>
                </div>
                <div className="flex flex-col items-center md:flex-row mt-4 md:mt-0">
                  <button className="w-[95px] h-[26px] bg-primary rounded-[5px] text-[15px] font-g-regular text-white">
                    Call Back
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center text-gray-500">
            No vendors data available
          </div>
        )}
      </div>
    </div>
  );
};

export default Vendors;
