
import React from 'react';
import TopNav from '../../../Components/TopNav/TopNav';

import BusinessData from './BusinessData';

const Business = () => {
    return (
        <div className='w-full'>
            <TopNav title='Business' />
            <BusinessData />
        </div>
    )
}

export default Business
