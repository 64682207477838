import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "../Layout/Layout";
import Dashboard from "../Pages/Dashboard/Dashboard";
import FeaturedVendors from "../Pages/FeaturedVendors/FeaturedVendors";
import HelpRequest from "../Pages/HelpRequest/HelpRequest";
import ProcessedOrder from "../Pages/ProcessedOrder/ProcessedOrder";
import ReportedBugs from "../Pages/ReportedBug/ReportedBugs";
import ReportedUsers from "../Pages/ReportedUsers/ReportedUsers";
import RequestPosting from "../Pages/RequestPosting/RequestPosting";
import CallBacks from "../Pages/CallBacks/CallBacks";
import ManageFaqs from "../Pages/Faqs/ManageFaqs";
import Profile from "../Pages/Profile/Profile";
import ArticlesList from "../Pages/ArticlesList/ArticlesList";
import ArticleDetails from "../Pages/ArticlesList/ArticleDetails";
import SliderManagemnt from "../Pages/SliderManagment/SliderManagemnt";
import RewardManagement from "../Pages/RewardManagement/RewardManagement";
import RewardDetails from "../Pages/RewardManagement/RewardDetails";
import AddReward from "../Pages/RewardManagement/AddReward";
import AddCategory from "../Pages/RewardManagement/AddCategory";
import RewardsStatus from "../Pages/RewardManagement/RewardsStatus";
import UpdateRewardPoints from "../Pages/RewardManagement/UpdateRewardPoints";
import AddRewardCopy from "../Pages/RewardManagement/AddRewardCopy";
import UpdateReward from "../Pages/RewardManagement/UpdateReward";
import AddSlide from "../Pages/SliderManagment/AddSlide";
import Article from "../Pages/ArticlesList/Article";
import Business from "../Pages/Business/Business";

const Authenticated = () => {
  return (
    <>
      <Layout>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/processedOrder" element={<ProcessedOrder />} />
          <Route path="/requestPosting" element={<RequestPosting />} />
          <Route path="/featuredVendors" element={<FeaturedVendors />} />
          <Route path="/helpRequest" element={<HelpRequest />} />
          <Route path="/reportedUsers" element={<ReportedUsers />} />
          <Route path="/reportedBugs" element={<ReportedBugs />} />
          <Route path="/callBacks" element={<CallBacks />} />
          <Route path="/manageFaqs" element={<ManageFaqs />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/articles" element={<ArticlesList />} />
          <Route path="/articles/addArticle" element={<Article />} />
          <Route path="/sliderManagment" element={<SliderManagemnt />} />
          <Route path="/addSlide" element={<AddSlide />} />
          <Route path="/RewardManagement" element={<RewardManagement />} />
          <Route path="/business" element={<Business />} />

          <Route
            path="/RewardManagement/rewardDetails/:rewardId"
            element={<RewardDetails />}
          />
          {/* <Route path="/addReward" element={<AddReward />} /> */}
          <Route
            path="/RewardManagement/addReward"
            element={<AddRewardCopy />}
          />
          <Route
            path="/RewardManagement/updateReward/:rewardId"
            element={<UpdateReward />}
          />
          <Route
            path="/RewardManagement/addCategory"
            element={<AddCategory />}
          />
          <Route
            path="/RewardManagement/rewardsStatus"
            element={<RewardsStatus />}
          />
          <Route
            path="/RewardManagement/rewardsPoint"
            element={<UpdateRewardPoints />}
          />
          <Route path="/articles/:id" element={<ArticleDetails />} />
          <Route path="*" element={<Dashboard />} />
        </Routes>
      </Layout>
    </>
  );
};

export default Authenticated;
