import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import TopNav from "../../../Components/TopNav/TopNav";
import tshir from "../../../Assets/img/tshrit.svg";
import { getRewardData } from "../../../Utils/HelperFunctions";
import DeleteRewardModal from "./DeleteRewardModal";
import { useNavigate } from "react-router-dom";

const RewardDetails = () => {
  const token = useSelector((state) => state?.Auth.user.data.token);
  const [rewardData, setRewardData] = useState(null);
  const { rewardId } = useParams(); // Get rewardId from URL parameters
  const navigate = useNavigate();

  console.log("rewardData", rewardData);
  const fetchRewardData = async () => {
    let data = await getRewardData(token, rewardId); // Pass rewardId to the function
    setRewardData(data.data);
  };

  useEffect(() => {
    fetchRewardData();
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRewardId, setSelectedRewardId] = useState(null);

  const toggleModal = (rewardId = null) => {
    setSelectedRewardId(rewardId);
    setIsModalOpen(!isModalOpen);
  };

  const renderSpecifications = (specification) => {
    const specItems = specification
      .split(". ")
      .filter((item) => item.trim() !== "");

    if (specItems.length === 1) {
      return (
        <p class="m-0 relative leading-[13px] text-darkslategray-200">
          {/* {specItems ? specItems[0] : ''} */}
          {specItems[0]}
        </p>
      );
    }

    return (
      <div class="self-stretch flex flex-col items-start justify-start gap-[18px] max-w-full z-[1]">
        {specItems.map((item, index) => (
          <div
            key={index}
            class="self-stretch flex flex-row flex-wrap items-end justify-start gap-[12px] max-w-full"
          >
            <div class="flex flex-col items-start justify-end pt-0 px-0 pb-0.5">
              <div class="w-1.5 h-1.5 relative rounded-[50%] bg-darkturquoise"></div>
            </div>
            <p class="m-0 flex-1 relative leading-[13px] inline-block min-w-[295px] max-w-full">
              {item}
            </p>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <TopNav title="Slider Managemnt" />
      <div class="w-[1160px] flex flex-row items-start justify-end py-0 px-6 box-border max-w-full max-h-[90%] overflow-auto">
        <div class="flex-1 shadow-[0px_4px_21px_12px_rgba(0,_0,_0,_0.05)] rounded-sm bg-white flex flex-col items-start justify-start pt-7 px-7 pb-[33px] box-border gap-[36px] max-w-full mq750:gap-[18px] mq750:pt-5 mq750:pb-[21px] mq750:box-border">
          <div class="w-[1129px] h-[593px] relative shadow-[0px_4px_21px_12px_rgba(0,_0,_0,_0.05)] rounded-sm bg-white hidden max-w-full"></div>
          <div class="w-full flex flex-col items-start justify-start gap-[30px] max-w-full">
            <div className="w-full flex flex-row items-start justify-between p-2">
              <div className="text-darkturquoise">
                <h3 className="m-0 relative text-inherit leading-[22px] capitalize font-bold font-inherit inline-block z-[1] mq450:text-2xl mq450:leading-[21px]">
                  category: {rewardData ? rewardData.categoryType.name : ""}
                </h3>
              </div>
              <div className="flex space-x-4">
                <Link
                  to={`/RewardManagement/updateReward/${rewardId}`}
                  className="bg-darkturquoise text-white px-4 py-2 rounded text-lg hover:bg-blue-600"
                >
                  Update
                </Link>
                <button
                  className="bg-red-500 text-white px-4 py-2 rounded text-lg hover:bg-red-600"
                  onClick={() => toggleModal(rewardData._id)}
                >
                  Delete
                </button>

                <DeleteRewardModal
                  isModalOpen={isModalOpen}
                  toggleModal={toggleModal}
                  rewardId={selectedRewardId} // Pass the selected reward ID
                />
              </div>
            </div>

            <div class="self-stretch flex flex-row items-end justify-start gap-[26px] max-w-full text-[34px] text-gray-100 mq1050:flex-wrap">
              <div class="shadow-[0px_0px_13px_rgba(0,_0,_0,_0.15)] rounded-3xs bg-lightcyan-100 flex flex-row items-start justify-start py-[34px] pr-8 pl-9 box-border min-w-[288px] z-[1] mq1050:flex-1">
                <div class="h-72 w-72 relative shadow-[0px_0px_13px_rgba(0,_0,_0,_0.15)] rounded-3xs bg-lightcyan-100 hidden"></div>
                {rewardData ? (
                  <>
                    {" "}
                    <img
                      class="h-[220px] w-[220px] relative object-contain z-[2] mq1050:flex-1"
                      loading="lazy"
                      alt=""
                      src={rewardData.image_url}
                    />
                  </>
                ) : (
                  <>
                    {" "}
                    <img
                      class="h-[220px] w-[220px] relative object-contain z-[2] mq1050:flex-1"
                      loading="lazy"
                      alt=""
                      src={tshir}
                    />
                  </>
                )}
              </div>
              <div class="flex-1 flex flex-col items-start justify-start gap-[34px] min-w-[426px] max-w-full mq750:gap-[17px] mq750:min-w-full">
                <div class="self-stretch flex flex-col items-start justify-start gap-[25px] max-w-full">
                  <h2 class="m-0 h-[29px] relative text-inherit leading-[34px] capitalize font-bold font-inherit inline-block shrink-0 max-w-full z-[1] mq450:text-xl mq450:leading-[20px] mq1050:text-[27px] mq1050:leading-[27px]">
                    {rewardData ? rewardData.name : ""}
                  </h2>
                  <p class="m-0 self-stretch h-24 relative text-base leading-[26px] text-darkslategray-200 whitespace-pre-wrap inline-block shrink-0 z-[1]">
                    {rewardData ? rewardData.description : ""}
                  </p>
                </div>
                <div class="w-[233px] flex flex-col items-start justify-start gap-[27px] max-w-full text-xl">
                  <div class="self-stretch flex flex-row items-start justify-start gap-[8px] mq750:flex-wrap">
                    <div class="flex-[0.9497] flex flex-col items-start justify-start py-0 pr-[9px] pl-0 box-border min-w-[117px] mq450:flex-1">
                      <div class="self-stretch relative leading-[20px] capitalize font-medium z-[1] mq450:text-base mq450:leading-[16px]">
                        total redemption:
                      </div>
                    </div>
                    <div class="flex flex-row items-start justify-start py-0 pr-2.5 pl-0 gap-[19px] text-darkturquoise">
                      <div class="relative leading-[20px] capitalize font-medium inline-block min-w-[25px] z-[1] mq450:text-base mq450:leading-[16px]">
                        04
                      </div>
                    </div>
                  </div>
                  <button class="cursor-pointer py-4 pr-[49px] pl-[50px] bg-[transparent] rounded-lg flex flex-row items-start justify-start whitespace-nowrap z-[1] border-[1px] border-solid border-darkturquoise hover:bg-cadetblue-200 hover:box-border hover:border-[1px] hover:border-solid hover:border-cadetblue-100">
                    <div class="w-[97px] relative text-lg leading-[18px] capitalize font-gilroy text-darkturquoise text-center inline-block min-w-[97px]">
                      {rewardData ? rewardData.points : ""} points
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="w-[472px] flex flex-col items-start justify-start gap-[26px] max-w-full text-9xl text-gray-100">
            <h3 class="m-0 w-[190px] h-6 relative text-inherit leading-[28px] capitalize font-semibold font-inherit inline-block shrink-0 z-[1] mq450:text-3xl mq450:leading-[22px]">
              specifications:
            </h3>
            <div class="self-stretch flex flex-col items-start justify-start gap-[18px] max-w-full text-lg text-darkslategray-200">
              <div class="self-stretch flex flex-col items-start justify-start gap-[18px] max-w-full z-[1]">
                {rewardData
                  ? renderSpecifications(rewardData.specification)
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default RewardDetails;
