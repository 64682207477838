import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SearchInput from "../../../Components/SearchInput/SearchInput";
import Spinner from "../../../Components/spinner/Spinner";
import { getCallBacksData } from "../../../Utils/HelperFunctions";

const CallBackUsers = () => {
  const [usersData, setUsersData] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filterUsers, setFilterUsers] = useState(null);
  const token = useSelector((state) => state?.Auth.user.data.token);

  const getData = async () => {
    let data = await getCallBacksData(token);
    console.log(data);
    setUsersData(data.result);
  };

  // Get Dummy Users Data
  useEffect(() => {
    getData();
  }, []);
  // useEffect(() => {
  //   const filter = usersData?.filter((data) =>
  //     data.user.name.toLowerCase().includes(searchText)
  //   );
  //   setFilterUsers(filter);
  // }, [searchText]);

  if (!usersData) {
    return <Spinner />;
  }

  return (
    <div className="max-h-[85vh]">
      {/* <SearchInput
    setSearchText={setSearchText}
    placeholder='Search Users by Name'
  /> */}
      <div className="container mx-auto w-[95%] h-[75vh] overflow-y-scroll custom-scroll-bar lg:w-[1070px] responsive-container">
        {usersData.length > 0 ? (
          usersData.map((data, index) => (
            <div
              className="bg-white py-2 w-[95%] mx-auto min-h-[150px] flex items-start px-4 mb-4 rounded-[10px] shadow-sm lg:w-[1045px] md:m-auto md:mb-4 responsive-inner-container"
              key={index}
            >
              <div className="flex flex-col items-center mr-12 w-[150px]">
                <img
                  src={data.user.profilePicture}
                  alt={data.user.name}
                  className="bg-gray-100 w-24 h-24 rounded-full mb-2"
                />
                <h3 className="text-lg font-bold items-center text-black text-center">
                  {data.user.name}
                </h3>
              </div>
              <div className="flex flex-col my-5 flex-grow mr-4">
                <h3 className="text-base md:text-xl text-[#000000] font-g-bold mb-2">
                  {data.subject}
                </h3>
                <p className="text-base font-g-regular text-customGray">
                  {data.description}
                </p>
              </div>
              <div className="flex flex-col justify-center my-auto items-center gap-2">
                {data.user.mobile ? (
                  <a
                    href={`tel:${data.user.mobile}`}
                    className="callback-btn flex items-center justify-center"
                  >
                    {data.user.mobile}
                  </a>
                ) : (
                  <></>
                )}
                {data.user.email ? (
                  <a
                    href={`mailto:${data.user.email}`}
                    className="callback-btn w-52 flex items-center justify-center"
                  >
                    {data.user.email}
                  </a>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ))
        ) : (
          <p className="text-center">No Data Found</p>
        )}
      </div>
    </div>
  );
};

export default CallBackUsers;
