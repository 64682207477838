export const dummyOrderData = [
  {
    name: 'James Robert',
    price: '10',
    category: 'Food',
    status: 'Completed',
    stack: '24',
  },
  {
    name: 'James Robert',
    price: '10',
    category: 'Food',
    status: 'Canceled',
    stack: '24',
  },
  {
    name: 'James Robert',
    price: '10',
    category: 'Electronic Devices',
    status: 'Completed',
    stack: '24',
  },
  {
    name: 'James Robert',
    price: '10',
    category: 'Food',
    status: 'Completed',
    stack: '24',
  },
  {
    name: 'James Robert',
    price: '10',
    category: 'Food',
    status: 'Completed',
    stack: '24',
  },
  {
    name: 'James Robert',
    price: '10',
    category: 'Food',
    status: 'Completed',
    stack: '24',
  },
  {
    name: 'James Robert',
    price: '10',
    category: 'Food',
    status: 'Completed',
    stack: '24',
  },
  {
    name: 'James Robert',
    price: '10',
    category: 'Clothing',
    status: 'Completed',
    stack: '24',
  },
  {
    name: 'James Robert',
    price: '10',
    category: 'Electronic Devices',
    status: 'Completed',
    stack: '24',
  },
  {
    name: 'James Robert',
    price: '10',
    category: 'Clothing',
    status: 'Canceled',
    stack: '24',
  },
  {
    name: 'James Robert',
    price: '10',
    category: 'Electronic Devices',
    status: 'Completed',
    stack: '24',
  },
  {
    name: 'James Robert',
    price: '10',
    category: 'Health & Beauty',
    status: 'Canceled',
    stack: '24',
  },
];
